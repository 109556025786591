import { useNavigate } from "react-router-dom";
import { Container, ContentContainer, Logo, NavBar, PageBackground, ScreenProtector } from "../Styling/NavBar-styling";
import HamburgerMenu from "../Components/HamburgerMenu";
import IconNavigation from "../Components/IconNavigation";
import { AdminMainContent, AdminCategoryContainer, AdminCategoryTitle, AdminSettingContainer, AdminSettingText, AdminSettingSelectBox, SmallTextSpan, AdminSettingTextarea, AdminButton } from "./AdminPage-styling";
import AddUser from "./AdminComponents/AddUser";
import { Organization, Inbox, Language, User } from "../Types";
import { useRef, useEffect, useState } from "react";
import { ax } from "../Utils";
import AddInbox from "./AdminComponents/AddInbox";
import AddWebsite from "./AdminComponents/AddWebsite";
import AddDocument from "./AdminComponents/AddDocument";
import { FaLanguage } from "react-icons/fa6";
import CompanyUsersList from "./AdminComponents/CompanyUsersList";
import Swal from "sweetalert2";
import CompanyInboxList from "./AdminComponents/CompanyInboxList";
import { useQueries, useQueryClient, useMutation } from "@tanstack/react-query";
import { useCurrentUser } from "../Queries/useCurrentUser";
import ChangeDescription from "./AdminComponents/ChangeDescription";
import { useOrganization } from "../Queries/useOrganization";
import { useAssistantLanguages } from "../Queries/useAssistantLanguages";
import { useInterfaceLanguages } from "../Queries/useInterfaceLanguages";
import WebScrapeConfigList from "./AdminComponents/WebScrapeConfigList";
import AssistantConfigPopUp from "./AdminComponents/AssistantConfigPopUp";
import { useAssistantConfigs } from "../Queries/useAssistantConfigs";


const AdminPage = () => {

  const [assistantLanguageId, setAssistantLanguageId] = useState<string>('');
  const [interfaceLanguageId, setInterfaceLanguageId] = useState<string>('');
  const [showAssistantConfigPopUp, setShowAssistantConfigPopUp] = useState(false);

  
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  
  const { data: currentUser } = useCurrentUser();
  const { data: organization, ...organizationQuery } = useOrganization(currentUser?.org_id);
  const { data: assistantLanguages, ...assistantLanguagesQuery } = useAssistantLanguages();
  const { data: interfaceLanguages, ...interfaceLanguagesQuery } = useInterfaceLanguages();
  const { data: assistantConfigs, ...assistantConfigsQuery } = useAssistantConfigs(currentUser?.org_id);
  
  const [
    companyInboxesQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: ['companyInboxes', currentUser?.org_id],//Väntar på endpoint
        queryFn: (): Promise<Inbox[]> => ax.get(`/feedback/email/company/all?company_id=${currentUser?.org_id}`).then(res => res.data),
        enabled: !!currentUser,
      }
    ],
  });


  const organizationMutation = useMutation({
    mutationFn: (requestBody: any) => {
      return ax.patch('/organization', requestBody);
    },
    onSuccess: (data, requestBody) => {
      queryClient.setQueryData(['organization', { obj_id: currentUser?.org_id }], data);
      queryClient.invalidateQueries({ queryKey: ['organization', { obj_id: currentUser?.org_id }] });
      if (requestBody.business_description) {
        Swal.fire({
          title: "Företagsbeskrivning uppdaterad!",
          icon: "success"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Språk uppdaterade!",
          icon: "success"
        });
      }
    },
    onError: (error, requestBody) => {
      if (requestBody.business_description) {
        Swal.fire({
          title: "Uppdatering av företagsbeskrivning misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
      else if (requestBody.default_assistant_language_id || requestBody.default_interface_language_id) {
        Swal.fire({
          title: "Uppdatering av språk misslyckades!",
          text: "Försök igen senare",
          icon: "error"
        });
      }
    }
  });



  const handleHome = async () => {
    navigate('/user');
  };

  const handleSaveBusinessDescription = async (newDescription: string) => {
    if (organization && newDescription !== organization.business_description) {
      const requestBody: any = {
        "id": organization.id,
        "business_description": newDescription,
      };
      organizationMutation.mutate(requestBody);
    }
  };

  const updateDefaultLanguages = async () => {
    if (organization && (assistantLanguageId !== organization.default_assistant_language_id || interfaceLanguageId !== organization.default_interface_language_id)) {
      const requestBody: any = {
        "id": organization.id,
        "default_assistant_language_id": assistantLanguageId,
        "default_interface_language_id": interfaceLanguageId,
      };
      console.log(requestBody);

      organizationMutation.mutate(requestBody);
    }
  };

  

  const handleDeleteInboxPopup = (inboxId: number, inboxName: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort inkorgen ${inboxName}?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort inkorg",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(inboxId);
      }
    });
  };

  const deleteInbox = async (inboxId: number) => {
    // try {
    //   await ax.delete(`/feedback/email/${inboxId}`);//Finns ej på riktigt ännu
    //   setCompanyInboxes(companyInboxes.filter((inbox) => inbox.id !== inboxId));
    //   Swal.fire({
    //     title: "Inkorgen borttagen!",
    //     icon: "success"
    //   });
    // } catch (error) {
    //   Swal.fire({
    //     title: "Radering av inkorg misslyckades!",
    //     text: "Error: " + error,
    //     icon: "error"
    //   });
    // }
  };

  const handleConfigureInbox = (inbox: Inbox) => {
    Swal.fire({
      title: "Konfigurera inkorg",
      html: `
        <p>E-postadress</p>
        <input id="email" class="swal2-input" value="${inbox.email}" disabled>
        <p>Namnge inkorg</p>
        <input id="inboxName" class="swal2-input" value="${inbox.inbox_name}">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Uppdatera inkorg",
      cancelButtonText: "Avbryt",
      preConfirm: () => {
        const inboxName = (document.getElementById('inboxName') as HTMLInputElement).value;
        return { inboxName };
      }

    }).then((result) => {
      if (result.isConfirmed) {
        updateInbox(inbox, result.value.inboxName);
      }
    }
    );
  };

  const updateInbox = async (inbox: Inbox, inboxName: string) => {
    // const requestBody = {
    //   "id": inbox.id,
    //   "inbox_name": inboxName
    // };
    // try {
    //   await ax.patch(`/feedback/email/${inbox.id}`, requestBody)//Finns ej på riktigt ännu
    //     .then((response) => setCompanyInboxes(companyInboxes.map((i) => i.id === inbox.id ? response.data : i)));
    //   Swal.fire({
    //     title: "Inkorg uppdaterad!",
    //     icon: "success"
    //   });
    // } catch (error) {
    //   Swal.fire({
    //     title: "Uppdatering av inkorg misslyckades!",
    //     text: "Error: " + error,
    //     icon: "error"
    //   });
    // }
  };

  const toggleAssistantConfigPopUp = () => {
    setShowAssistantConfigPopUp(!showAssistantConfigPopUp);
  }




  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <Logo onClick={handleHome}>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <AdminMainContent>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Språk <FaLanguage/></AdminCategoryTitle>
                <AdminSettingContainer>
                  <AdminSettingText>Hemsidans språk</AdminSettingText>
                  <AdminSettingSelectBox
                    defaultValue={organization?.default_interface_language_id}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setInterfaceLanguageId(event.target.value)}
                  >
                    {interfaceLanguages?.map((language) => (
                      <option key={language.id} value={language.id}>{language.name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Assistentens språk</AdminSettingText>
                  <AdminSettingSelectBox
                    defaultValue={organization?.default_assistant_language_id}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setAssistantLanguageId(event.target.value)}
                  >
                    {assistantLanguages?.map((language) => (
                      <option key={language.id} value={language.id}>{language.name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminButton onClick={updateDefaultLanguages}>
                  Spara
                </AdminButton>

              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till användare</AdminCategoryTitle>
                <AddUser />
                <AdminSettingContainer>
                  <AdminSettingText>Registrerade användare på {organization?.name}:</AdminSettingText>
                  <CompanyUsersList/>
                </AdminSettingContainer>

              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till inkorg</AdminCategoryTitle>
                <AddInbox />
                <AdminSettingContainer>
                  <AdminSettingText>Registrerade inkorgar på {organization?.name}:</AdminSettingText>
                  <CompanyInboxList
                    companyInboxes={companyInboxesQuery.data || []}
                    handleDeleteInboxPopup={handleDeleteInboxPopup}
                    handleConfigureInbox={handleConfigureInbox}
                  />
                </AdminSettingContainer>
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Företagsbeskrivning <SmallTextSpan>(Valfritt men rekommenderat då det hjälper assistenten att förstå sin uppgift)</SmallTextSpan></AdminCategoryTitle>
                <ChangeDescription
                  businessDescription={organization?.business_description || ""}
                  handleSaveBusinessDescription={handleSaveBusinessDescription}
                />
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Hemsidor</AdminCategoryTitle>
                Lägg till en webbplats för ragge att läsa
                <AddWebsite />
                <AdminSettingContainer>
                  <AdminSettingText>Webbplatser som ragge läser:</AdminSettingText>
                  <WebScrapeConfigList />
                </AdminSettingContainer>
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till texter</AdminCategoryTitle>
                <AddDocument />
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Konfigurera ragge</AdminCategoryTitle>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för intern chattbot</AdminSettingText>
                  <AdminSettingSelectBox>
                    {assistantConfigs?.map((config) => (
                      <option key={config.id} value={config.id}>{config.config_name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för feedback assistent</AdminSettingText>
                  <AdminSettingSelectBox>
                    {assistantConfigs?.map((config) => (
                      <option key={config.id} value={config.id}>{config.config_name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för marknadsföringsassistent</AdminSettingText>
                  <AdminSettingSelectBox>
                    {assistantConfigs?.map((config) => (
                      <option key={config.id} value={config.id}>{config.config_name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för extern chattbot</AdminSettingText>
                  <AdminSettingSelectBox>
                    {assistantConfigs?.map((config) => (
                      <option key={config.id} value={config.id}>{config.config_name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminButton onClick={toggleAssistantConfigPopUp}>
                  Skapa ny konfiguration
                </AdminButton>
              </AdminCategoryContainer>
            </AdminMainContent>
          </ContentContainer>
        </Container>
        {showAssistantConfigPopUp && (
          <AssistantConfigPopUp
            toggleAssistantConfigPopUp={toggleAssistantConfigPopUp}
          />
        )}
      </ScreenProtector>
    </PageBackground>
  );
};

export default AdminPage;