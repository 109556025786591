export function formatDateTime(dateTimeString: string): string {
  const dateTime = new Date(dateTimeString);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const oneYear = 365 * 24 * 60 * 60 * 1000; // milliseconds in one year
  const difference = now.getTime() - dateTime.getTime();

  // Format options for displaying the date and time
  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
  const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  const yearOptions: Intl.DateTimeFormatOptions = { year: 'numeric' };

  if (difference < oneDay) {
      // Less than a day ago, show time
      return dateTime.toLocaleTimeString('sv-SE', timeOptions);
  } else if (difference < oneYear) {
      // More than a day but less than a year ago, show date
      return dateTime.toLocaleDateString('sv-SE', dateOptions);
  } else {
      // More than a year ago, show year
      return dateTime.toLocaleDateString('sv-SE', yearOptions);
  }
};