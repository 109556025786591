import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { WebScrapeConfig } from "../Types";



export const useWebScrapeConfigs = (orgId: string | undefined) => {
  return useQuery<WebScrapeConfig[], Error>({
    queryKey: ['web_scrape_config', 'all', { org_id: orgId }],
    queryFn: (): Promise<WebScrapeConfig[]> => ax.get(`/web_scrape_config/all/`).then(res => res.data),
    enabled: !!orgId,
  });
}