import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Feedback } from "../Types";

export const useFeedbackList = () => {
  return useQuery<Feedback[], Error>({
    queryKey: ["feedback", "all", "list"],
    queryFn: (): Promise<Feedback[]> => ax.get('/feedback/all/list/').then(res => res.data),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 30, //hur ofta behöver feedbacklistan uppdateras?
  });
};