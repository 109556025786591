import { useQuery } from '@tanstack/react-query';
import { Organization } from '../Types';
import { ax } from '../Utils';



export const useOrganization = (orgId: string | undefined) => {
  return useQuery<Organization, Error>({
    queryKey: ['organization', { obj_id: orgId }],
    queryFn: (): Promise<Organization> => ax.get(`/organization?obj_id=${orgId}`).then(res => res.data),
    enabled: !!orgId,
  });
};