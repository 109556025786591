import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Marketing } from "../Types";
import { ax } from "../Utils";


export const useMarketingMutation = () => {
  const queryClient = useQueryClient();

  const marketingMutation = useMutation({
    mutationFn: (requestBody: any) => {
      if (requestBody.id) {
        return ax.patch('/marketing', requestBody);
      }
      return ax.post('/marketing', requestBody);
    },
    onSuccess: (marketing) => {
      queryClient.setQueryData(['marketing', { obj_id: marketing.data.id }], marketing.data);
      queryClient.setQueryData(['marketing', 'all'], (oldData: Marketing[]) => 
        oldData.filter((m) => m.id !== marketing.data.id).concat(marketing.data));
      queryClient.invalidateQueries({ queryKey: ['marketing', {obj_id: marketing.data.id}] });
      queryClient.invalidateQueries({ queryKey: ['marketing', 'all'] });
    },
  });

  return marketingMutation;
};