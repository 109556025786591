import React from "react";
import { Link } from "react-router-dom";
import { FaBullhorn, FaRegComments, FaRobot, FaRegChartBar, FaBook, FaSearch, FaUserTie, FaEnvelope } from "react-icons/fa";
import "./IconNavigation-styling.css";
import { FaRegEnvelope } from "react-icons/fa6";
import { TbMessageChatbot } from "react-icons/tb";
const IconNavigation = () => {

  const handleClick = (filter: string) => {
    //TODO: mutera query som lagrar sökfilter i feedback
  };

  return (
    <div className="vertical-navbar">
      <Link to="/user" className="nav-item">
        <FaRobot className="nav-icon"/>
        <span className="nav-text">Chatbot</span>
      </Link>
      <Link to="/feedback" className="nav-item">
        <FaRegComments className="nav-icon"/>
        <span className="nav-text">Feedback</span>
      </Link>
      <Link to="/feedback" className="nav-item nav-item-sub"
        onClick={() => handleClick("email")}
      >
        <FaRegEnvelope className="nav-icon nav-icon-sub"/>
        <span className="nav-text nav-text-sub">E-post</span>
      </Link>
      <Link to="/feedback" className="nav-item nav-item-sub"
        onClick={() => handleClick("external_chatbot")}
      >
        <TbMessageChatbot className="nav-icon nav-icon-sub"/>
        <span className="nav-text nav-text-sub">Kundchatbot</span>
      </Link>
      <Link to="/catalogue" className="nav-item">
        <FaSearch className="nav-icon"/>
        <span className="nav-text">Kunskapsbanken</span>
      </Link>
      <Link to="/marketing" className="nav-item">
        <FaBullhorn className="nav-icon"/>
        <span className="nav-text">Marknadsföring</span>
      </Link>
      <Link to="/stats" className="nav-item">
        <FaRegChartBar className="nav-icon"/>
        <span className="nav-text">Statistik</span>
      </Link>
      <Link to="/admin" className="nav-item">
        <FaUserTie className="nav-icon"/>
        <span className="nav-text">Admin</span>
      </Link>
    </div>
  );
}

export default IconNavigation;