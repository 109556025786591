import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Email } from "../Types";



export const useEmailDraftMutation = () => {
  const queryClient = useQueryClient();

  const emailDraftMutation = useMutation({
    mutationFn: (requestBody: any) => {
      console.log('requestBody: ', requestBody);
      return ax.put('/email/draft/', requestBody);
    },
    onSuccess: (response) => {
      console.log("onSuccess", response.data.to_attendees.map((a: any) => a.identifier));
      const module = "feedback";

      queryClient.setQueryData([{feedback_type: "email"}, "feedback", { feedback_id: response.data.feedback_id }], (oldData: Email[]) => {
        const existingDraft = oldData.find((email: Email) => email.id === response.data.id);
        if (existingDraft) {
          return oldData.filter((email: Email) => email.id !== existingDraft.id).concat(existingDraft);
        }
        else {
          return [...oldData, response.data];
        }

      });
      queryClient.setQueryData(['content', { module }, { obj_id: response.data.feedback_id }], response.data.body);

      queryClient.invalidateQueries({ queryKey: [{feedback_type: "email"}, "feedback", { feedback_id: response.data.feedback_id }] });
      queryClient.invalidateQueries({ queryKey: ['content', { module }, { obj_id: response.data.feedback_id }] });
    },
  });

  return emailDraftMutation;
};