import React from 'react';
import TagsInput from 'react-tagsinput';
import './EmailTagsInput-styling.css';
type EmailTagProps = {
  emailAddresses: {to: string[], cc: string[], bcc: string[]};
  showCc: boolean;
  showBcc: boolean;
  setShowCc: (showCc: boolean) => void;
  setShowBcc: (showBcc: boolean) => void;
  toInputRef: React.RefObject<HTMLInputElement>;
  ccInputRef: React.RefObject<HTMLInputElement>;
  bccInputRef: React.RefObject<HTMLInputElement>;
  handleEmailsChange: (emails: string[], recipientType: string) => void;
}

const EmailTagsInput = (props: EmailTagProps) => {

  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 10px 0px 10px' }}>
      <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
        <div className='recipient-type-text'>Till</div>
        <TagsInput
          value={props.emailAddresses.to}
          onChange={(emails) => props.handleEmailsChange(emails, "to_attendees")}
          validationRegex={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}
          inputProps={{ placeholder: '', ref: props.toInputRef }}
        />
      </div>
      {(props.showCc || props.emailAddresses.cc.length > 0) && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <div className='recipient-type-text'>Kopia</div>
          <TagsInput
            value={props.emailAddresses.cc}
            onChange={(emails) => props.handleEmailsChange(emails, "cc_attendees")}
            validationRegex={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}
            inputProps={{ placeholder: '', ref: props.ccInputRef }}
          />
        </div>
      )}
      {(props.showBcc || props.emailAddresses.bcc.length > 0) && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <div className='recipient-type-text'>Hemlig kopia</div>
          <TagsInput
            value={props.emailAddresses.bcc}
            onChange={(emails) => props.handleEmailsChange(emails, "bcc_attendees")}
            validationRegex={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}
            inputProps={{ placeholder: '', ref: props.bccInputRef }}
          />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignSelf: 'safe end'}}>
        {(!props.showCc && props.emailAddresses.cc.length === 0) &&
          <button 
            className='cc-button'
            onClick={() => props.setShowCc(true)}
          >
            Kopia
          </button>
        }
        {(!props.showBcc && props.emailAddresses.bcc.length === 0) &&
          <button 
            className='cc-button'
            onClick={() => props.setShowBcc(true)}
          >
            Hemlig kopia
          </button>
        }
      </div>
    </div>
  );
};

export default EmailTagsInput;