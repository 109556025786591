import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { useNavigate } from 'react-router-dom';
import { ax, api_base_url } from "../Utils";
import TextEditor from './Sections/TextEditor';
import CampaignList from './Sections/CampaignList';
import MarketingCopilot from './Sections/MarketingCopilot';
import { Marketing } from '../Types';
import { Id, toast } from 'react-toastify';
import { Editor } from '@tiptap/react';
import { debounce, set, update } from 'lodash';
import IconNavigation from '../Components/IconNavigation';
import HamburgerMenu from '../Components/HamburgerMenu';
import { useCurrentUser } from '../Queries/useCurrentUser';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import { useContentMarketing } from '../Queries/useContentMarketing';
import { useQueryClient } from '@tanstack/react-query';
import { useMarketingList } from '../Queries/useMarketingList';
import { useSelectedMarketing } from '../Queries/useSelectedMarketing';
import { useMarketingMutation } from '../Mutations/MarketingMutation';
import { useAssistantInputText } from '../Queries/useAssistantInputText';

const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";



const MarketingPage = () => {

  const module = 'marketing';

  const [inputText, setInputText] = useState('');

  const conversationRef = useRef<HTMLDivElement>(null);
  const toastId = useRef<Id | null>(null);
  const editorRef = useRef<Editor | null>(null);

  const [lastClickedId, setLastClickedId] = useState<string | null>(null);



  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { selectedMarketing, updateSelectedMarketing } = useSelectedMarketing();
  const { data: currentUser } = useCurrentUser();
  const { assistantChat, generateAnswer, streamWelcomeMessage, addMessage } = useAssistantChat(module, selectedMarketing?.id); 
  const { data: answerLoading } = useAnswerLoading(module, selectedMarketing?.id);
  const { data: content } = useContentMarketing(module, selectedMarketing?.id);
  const { data: marketings, ...marketingsQuery } = useMarketingList();
  //const { assistantInputText, updateAssistantInputText } = useAssistantInputText(module);

  const marketingMutation = useMarketingMutation();

  const marketingsLengthRef = useRef(marketings?.length);
  const selectedMarketingRef = useRef(selectedMarketing);
  const isInitialLoad = useRef(true);


  useEffect(() => {
    if (marketings && isInitialLoad.current) {
      marketingsLengthRef.current = marketings.length;
      isInitialLoad.current = false;
    }
  }, [marketings]);

  useEffect(() => {
    scrollToBottom();
  }, [assistantChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('marketingAssistantInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);


  useEffect(() => {
    if (selectedMarketing && (selectedMarketing.id === selectedMarketingRef.current?.id || marketings?.length !== marketingsLengthRef.current)) {
      marketingsLengthRef.current = marketings?.length;
      setLastClickedId(selectedMarketing.id);
    }
    else {
      if (selectedMarketing) {
        setLastClickedId(selectedMarketing.id);
        updatePage(selectedMarketing.id);
      }
      else {
        setLastClickedId(null);
        blankPage();
      }
    }
    setInputText('');
    selectedMarketingRef.current = selectedMarketing;
  }, [selectedMarketing]);


  const updatePage = async (id: string) => { 
    if (assistantChat.length === 1 && assistantChat[0] === '') {
      streamWelcomeMessage(selectedMarketing);
    }
  };

  const blankPage = () => {
    if (assistantChat.length === 1 && assistantChat[0] === '') {
      streamWelcomeMessage(selectedMarketing);
    }
  };

  const handleHome = async () => {
    navigate('/user');
  };

  const handleNewMarketing = () => {
    updateSelectedMarketing(null);
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer({text: inputText, module_name: module, current_content: editorRef.current?.getHTML() || '', module_ref_id: selectedMarketing?.id}).then((gotContent) => {
      if (gotContent) {
        handleChange(editorRef.current?.getHTML() || '', editorRef.current?.getText() || '');
      }
    });
    setInputText('');
    //updateAssistantInputText('');
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: string; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    //updateAssistantInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };
  
  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  // Debounced function that shows the notification
  const handleChange = useCallback(debounce((html: string, text: string) => {
    if (text && html !== selectedMarketing?.body) {

      if (selectedMarketing) {
        toastId.current = toast.info('Saving draft...');

        const requestBody: any = {
          text: text,
          body: html,
          id: selectedMarketing.id,
        }

        marketingMutation.mutate(requestBody, {
          onSuccess: (data) => {
            toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
          },
          onError: (error) => {
            toast.update(toastId?.current ?? '', {type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
          }
        });
      }

        //Shouldn't autosave new marketings (first save should be manual)
      // else {
      //   const requestBody1: any = {
      //     text: text,
      //     max_nbr_words: 3,
      //   }

      //   ax.post('/generate/title', requestBody1)
      //     .then((response) => {

      //     const requestBody: any = {
      //       title: response.data,
      //       text: text,
      //       body: html,
      //     }

      //     marketingMutation.mutate(requestBody, {
      //       onSuccess: (marketing) => {
      //         toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
      //         updateSelectedMarketing(marketing.data);
      //       },
      //       onError: (error) => {
      //         toast.update(toastId?.current ?? '', {type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
      //       }
      //     });

      //     // ax.patch(`/marketing`, requestBody)
      //     //   .then(async (response) => {
      //     //     toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
      //     //     await ax.get('marketing/all').then((response2) => setMarketings(response2.data));
      //     //     setSelectedMarketing(response.data)
      //     //   });
      //     });
      // }
        
    }
  }, 5000), [selectedMarketing]); // 5 seconds

  const saveMarketing = async () => {
    if (!editorRef.current?.getText() || editorRef.current?.getHTML() === selectedMarketing?.body) {
      return;
    }

    toastId.current = toast.info('Saving draft...');
    if (selectedMarketing) {
      const requestBody: any = {
        text: editorRef.current?.getText(),
        body: editorRef.current?.getHTML(),
        id: lastClickedId,
      }

      marketingMutation.mutate(requestBody, {
        onSuccess: (data) => {
          toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
        },
        onError: (error) => {
          toast.update(toastId?.current ?? '', {type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
        }
      });
    }

    else {
      const requestBody1: any = {
        text: editorRef.current?.getText(),
        max_nbr_words: 3,
      }

      const marketingTitle = await ax.post('/generate/title', requestBody1);

      const requestBody: any = {
        title: marketingTitle.data,
        text: editorRef.current?.getText(),
        body: editorRef.current?.getHTML(),
      }

      marketingMutation.mutate(requestBody, {
        onSuccess: (marketing) => {
          toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
          updateSelectedMarketing(marketing.data);
          queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: undefined }], ['']);
          queryClient.setQueryData<string>(['content', { module }, { obj_id: undefined }], '');
        },
        onError: (error) => {
          toast.update(toastId?.current ?? '', {type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
        }
      });
    }
  };


  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <CampaignList
              lastClickedId={lastClickedId}
              handleNewMarketing={handleNewMarketing}
            />
            <TextEditor
              content={content}
              saveMarketing={saveMarketing}
              handleChange={handleChange}
              selectedMarketing={selectedMarketing}
              selectedMarketingRef={selectedMarketingRef}
              cancelHandleChange={handleChange.cancel}
              editorRef={editorRef}
            />
            <MarketingCopilot
              inputText={inputText}
              module={module}
              handleTextareaChange={handleTextareaChange}
              handleSend={handleSend}
              handleEnterSend={handleEnterSend}
              conversationRef={conversationRef}
              copilotChat={assistantChat}
              answerLoading={answerLoading}
            />
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};
export default MarketingPage;