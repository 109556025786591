import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import {
    WelcomeSection,
    WelcomeHeader,
    DashboardSection,
    DashboardHeader,
    DashboardRowContainer,
    DashboardRowHeader,
    DashboardStatBox,
} from "./StatsPage-styling";
import{
  MainContent,
  NavigationSection,
  NavigationHeader,
  NavigationItem,
  NavigationListContainer,
  SelectedNavigationItem,
} from "../UserPage/UserPage-styling"
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { ax } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { KPIs, User } from '../Types';
import HamburgerMenu from '../Components/HamburgerMenu';
import IconNavigation from '../Components/IconNavigation';
import { Stats } from 'fs';
import { useCurrentUser } from '../Queries/useCurrentUser';


var logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png"



const StatsPage = () => {

  const [userKpis, setUserKpis] = useState<KPIs | null>(null);
  const [companyKpis, setCompanyKpis] = useState<KPIs | null>(null);
  //const [currentMonth, setCurrentMonth] = useState('');

  const isFetchingUser = useRef(false);
  const isFetchingKpis = useRef(false);

  const { data: currentUser } = useCurrentUser();


  // useEffect(() => {
  //   if (!props.currentUser && !isFetchingUser.current) {
  //     isFetchingUser.current = true;
  //     ax.get('/users/company/whoami')
  //       .then((response) => props.setCurrentUser(response.data));
  //   }
  //   if (!userKpis && !companyKpis && !isFetchingKpis.current) {
  //     isFetchingKpis.current = true;
  //     ax.get('/stats/basic_kpis').then(response => {
  //       setUserKpis(response.data.user);
  //       setCompanyKpis(response.data.company);
  //     });
  //     //const month = new Intl.DateTimeFormat('sv', { month: 'long' }).format(new Date());
  //     //setCurrentMonth(month);
  //   }

  // });

  const navigate = useNavigate();

  const handleHome = async () => {
    navigate('/user');
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSettings = async () => {
    //Show menu of settings
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };
  const handleFeedback = async () => {
    navigate('/feedback');
  };
  const handleCatalogue = async () => {
    navigate('/catalogue');
  };
  const handleCopilot = async () => {
    navigate('/user');
  };
  const handleCreateTemplate = () => {
    navigate('/admin/templates')
  };
  const handleMarketing = async () => {
    navigate('/marketing');
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <MainContent>
              <WelcomeSection>
                <WelcomeHeader>Statistik för {currentUser?.first_name}</WelcomeHeader>
                <DashboardSection>
                  <DashboardHeader>Dashboard</DashboardHeader>
                  <DashboardRowHeader>Min statistik</DashboardRowHeader>
                  <DashboardRowContainer>
                    <DashboardStatBox>
                      <DashboardRowHeader>Öppna ärenden</DashboardRowHeader>
                      <DashboardHeader>{/*userKpis?.nbr_open_errands*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Avslutade ärenden sista 30 dagarna</DashboardRowHeader>
                      <DashboardHeader>{/*userKpis?.nbr_closed_errands_last_30_days*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Skickade svar sista 30 dagarna</DashboardRowHeader>
                      <DashboardHeader>{/*userKpis?.nbr_responses_last_30_days*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Totala ärenden</DashboardRowHeader>
                      <DashboardHeader>{/*userKpis?.nbr_total_errands*/}</DashboardHeader>
                    </DashboardStatBox>
                  </DashboardRowContainer>
                  <DashboardRowHeader>Hela företagets statistik</DashboardRowHeader>
                  <DashboardRowContainer>
                    <DashboardStatBox>
                      <DashboardRowHeader>Öppna ärenden</DashboardRowHeader>
                      <DashboardHeader>{/*companyKpis?.nbr_open_errands*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Avslutade ärenden sista 30 dagarna</DashboardRowHeader>
                      <DashboardHeader>{/*companyKpis?.nbr_closed_errands_last_30_days*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Skickade svar sista 30 dagarna</DashboardRowHeader>
                      <DashboardHeader>{/*companyKpis?.nbr_responses_last_30_days*/}</DashboardHeader>
                    </DashboardStatBox>
                    <DashboardStatBox>
                      <DashboardRowHeader>Totala ärenden</DashboardRowHeader>
                      <DashboardHeader>{/*companyKpis?.nbr_total_errands*/}</DashboardHeader>
                    </DashboardStatBox>
                  </DashboardRowContainer>
                </DashboardSection>
              </WelcomeSection>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default StatsPage;