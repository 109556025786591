import React, { useState, useEffect, useRef } from 'react';
import {
    LoginBox,
    Title,
    Label,
    Input,
    Button,
    ForgotPasswordWrapper,
    ForgotPasswordText,
    LoginContent,
    MainContent,
} from "./LoginPage-styling";
import { Container, ContentContainer, Logo, LogoImg, Nav, NavBar, NavItem, PageBackground, ScreenProtector } from "../Styling/NavBar-styling"
import { ax } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { User } from '../Types';


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png"



const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //Focus the username input by default
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, []);

  const handleHome = async () => {
    navigate('/');
  };

  const handleForgotPassword = async () => {
    navigate('/login/forgot');
  };

  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.set('username', username);
      formData.set('password', password);
      
      //This is just to see the contents of the FormData object
      const formDataArray: [string, FormDataEntryValue][] = Array.from(formData.entries());
      formDataArray.forEach(([key, value]) => {
      });
  
      // Send the login data to the backend using Axios
      const response = await ax.post(
        '/auth/login',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )

      // Check the response from the backend and handle accordingly
      if (response.status === 200 || response.status === 204) {
        navigate('/user');
      }

    } catch (error) {
      console.error('Error while trying to log in:', error);
      setError('Fel användarnamn eller lösenord! Försök igen.');
      // Handle any other errors that may occur during the login process.
    }
  };
  const handleUsernameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  };
  const handlePasswordKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>

          </NavBar>
          <ContentContainer>
            <MainContent>
              <LoginBox>
                <LoginContent>
                  <Title>Logga in</Title>
                  <div>
                    <Label>Email:</Label>
                    <Input type="text" value={username} 
                      onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                        setUsername(e.target.value);
                        setError('');
                      }}
                      onKeyDown={handleUsernameKeyPress}
                      $error={!!error}
                      ref={usernameInputRef} />
                  </div>
                  <div>
                    <Label>Lösenord:</Label>
                    <Input type="password" value={password}
                      onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                        setPassword(e.target.value);
                        setError('');
                      }}
                      onKeyDown={handlePasswordKeyPress}
                      $error={!!error}
                      ref={passwordInputRef}
                    />
                  </div>
                  <Button onClick={handleLogin}>Logga in</Button>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </LoginContent>
                <ForgotPasswordWrapper>
                  <ForgotPasswordText onClick={handleForgotPassword}>Glömt lösenord?</ForgotPasswordText>
                </ForgotPasswordWrapper>
              </LoginBox>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default LoginPage;