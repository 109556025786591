import { useQuery } from "@tanstack/react-query";


export const useAnswerLoading = (module: string, obj_id: string | undefined) => {
  //console.log("answer_loading");
  return useQuery<boolean[]>({
    queryKey: ['answer_loading', {module}, { obj_id }],
    queryFn: () => Promise.resolve([]), // Initialize with an empty array
    enabled: false, // Disabled fetching
    initialData: [], // Sets the initial state to an empty boolean array
  });
};