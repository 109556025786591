import { useQuery } from "@tanstack/react-query"
import { ChatbotSettings } from "../Types"


export const useChatbotSettings = () => {
  return useQuery<ChatbotSettings | undefined, Error>({
    queryKey: ['chatbot_settings'],
    queryFn: async (): Promise<ChatbotSettings | undefined> => {
      return undefined;
    },
    enabled: false,
    initialData: undefined,
  });
};