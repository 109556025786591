import { useMemo } from 'react';
import { useFeedbackData } from './useFeedbackData';
import { Email, FeedbackType } from '../Types';

export const useDraft = (feedback_type: FeedbackType | undefined, feedback_id: string | undefined) => {
  const { data: feedbackData, ...feedbackDataQuery } = useFeedbackData(feedback_type, feedback_id);

  const draftEmail = useMemo(() => {
    if (!feedbackData || feedbackData.length === 0) return null;
    return feedbackData.find((message: Email) => !message.sent_at) ?? { body: '' } as Email;
  }, [feedbackData]);


  return { data: draftEmail, ...feedbackDataQuery };
};
