import { useQuery } from "@tanstack/react-query";
import { Language } from "../Types";
import { ax } from "../Utils";



export const useInterfaceLanguages = () => {
  return useQuery<Language[], Error>({
    queryKey: ['interface_language', 'all'],
    queryFn: (): Promise<Language[]> => ax.get('/interface_language/all/').then(res => res.data),
  });
};