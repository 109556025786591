import { useEffect } from 'react';

const ChatbotContainer = () => {
  useEffect(() => {
    const initializeChatbot = () => {
      const container = document.getElementById('chatbot-container');
      if (container && !container.querySelector('iframe')) {
        window.initializeChatbotWidget({
          src: 'http://localhost:3000/chatbot', // URL to the source of the chatbot. The chatbot will not work without this.
          clientId: 'uniqueClient123', // Your unique client identifier. The chatbot will not work without this.
          theme: '#f0f0f0', // Your choice of color theme for the chatbot. Default is '#0f86a1'.
          width: '350px', // Width of the chatbot. Default is '350px'.
          height: '550px', // Height of the chatbot. Default is '550px'.
          sandbox: 'allow-same-origin allow-scripts' // Security sandboxing options. 
        });
        console.log('Chatbot initialized');
      }
    };

    if (!document.querySelector(`script[src='http://localhost:3000/scripts/chatbotInit.js']`)) {
      const script = document.createElement('script');
      script.src = 'http://localhost:3000/scripts/chatbotInit.js';
      script.async = true;
      script.onload = initializeChatbot;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      initializeChatbot();
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'http://localhost:3000/scripts/chatbotToggleInit.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div id="chatbot-container" style={{ display: 'none' }}></div>
      <div id='chatbot-toggle-button-container'></div>
    </>
  );
};

export default ChatbotContainer;
