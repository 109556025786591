import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaCog, FaTimes } from 'react-icons/fa';
import { FaLanguage } from 'react-icons/fa6';
import { ImExit } from 'react-icons/im';
import './HamburgerMenu-styling.css';
import { ax } from '../Utils';
import { useQueryClient } from '@tanstack/react-query';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && buttonRef.current && menuRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      menuRef.current.style.top = `${buttonRect.bottom}px`; // 8px space below the button
      menuRef.current.style.right = `${window.innerWidth - buttonRect.right}px`;
    }
  }, [isOpen]);

  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    queryClient.clear();
    window.location.href = 'https://ragge.ai/';
  };

  return (
    <div className="hamburger-menu">
      <button
        ref={buttonRef}
        onClick={toggleMenu}
        className="hamburger-icon"
        aria-label="Toggle Menu"
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div ref={menuRef} className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
        <button className="menu-item">
          <FaCog className="menu-icon" />Inställningar
        </button>
        <button className="menu-item">
          <FaLanguage className="menu-icon" />Språk
        </button>
        <button className="menu-item" onClick={handleSignOut}>
          <ImExit className="menu-icon" />Logga ut
        </button>
      </div>
    </div>
  );
};

export default HamburgerMenu;
