import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { User } from "../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton } from "../AdminPage-styling";
import { useCurrentUser } from "../../Queries/useCurrentUser";
import { ax } from "../../Utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { useWebScrapeConfigs } from "../../Queries/useWebScrapeConfigs";
import { useDataModels } from "../../Queries/useDataModels";
import { formatDateTime } from "../../Functions/formatDateTime";


const WebScrapeConfigList = () => {

  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const { data: webScrapeConfigs, ...webScrapeConfigsQuery } = useWebScrapeConfigs(currentUser?.org_id);
  const { data: dataModels, ...dataModelsQuery } = useDataModels();

  const deleteWebScrapeConfigMutation = useMutation({
    mutationFn: (configId: string) => {
      return ax.delete(`/web_scrape_config/${configId}`);
    },
    onSuccess(data, configId) {
      queryClient.setQueryData(['web_scrape_config', 'all', { org_id: currentUser?.org_id }], (oldData: User[]) => oldData.filter((config) => config.id !== configId));
      queryClient.invalidateQueries({ queryKey: ['web_scrape_config', 'all', { org_id: currentUser?.org_id }] });
    },
  });

  const editWebScrapeConfigMutation = useMutation({
    mutationFn: (requestBody: any) => {
      return ax.patch(`/web_scrape_config`, requestBody);
    },
    onSuccess(data, variables) {
      queryClient.setQueryData(['web_scrape_config', 'all', { org_id: currentUser?.org_id }], (oldData: User[]) => oldData.map((config) => config.id === variables.id ? data : config));
      queryClient.invalidateQueries({ queryKey: ['web_scrape_config', 'all', { org_id: currentUser?.org_id }] });
    }
  });


  const handleDeleteWebScrapeConfigPopup = (configId: string, configName: string, startUrl: string) => {
    Swal.fire({
      title: `Är du säker på att du vill sluta hämta data från ${startUrl}?`,
      text: "All data som samlats in från webbplatsen kommer att tas bort!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort hemsida",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWebScrapeConfig(configId);
      }
    });
  };

  const deleteWebScrapeConfig = (configId: string) => {
    deleteWebScrapeConfigMutation.mutate(configId);
    if (deleteWebScrapeConfigMutation.isSuccess) {
      Swal.fire({
        title: "Hemsidan har tagits bort!",
        icon: "success",
      });
    }
    else if (deleteWebScrapeConfigMutation.isError) {
      Swal.fire({
        title: "Radering av hemsida misslyckades!",
        text: "Försök igen senare.",
        icon: "error",
      });
    }
  };

  const handleConfigureWebScrapeConfig = (config: any) => {
    Swal.fire({
      title: "Ändra inställningar för webbplats",
      html: `
      <p>URL</p>
      <input id="start_url" class="swal2-input" placeholder="URL" value="${config.start_url}">
      <p>Namn</p>
      <input id="name" class="swal2-input" placeholder="Namn" value="${config.name}">
      <p>Följ länkar?</p>
      <input id="follow_links" type="checkbox" class="swal2-input" ${config.follow_links ? "checked" : ""}>
      <p>Följ bara länkar som innehåller start-URLen?</p>
      <input id="only_follow_start_url_children" type="checkbox" class="swal2-input" ${config.only_follow_start_url_children ? "checked" : ""}>
      <p>Interval för uppdatering i timmar</p>
      <input id="update_interval" type="number" class="swal2-input" value="${config.refresh_interval}">
      <p>Datatyp</p>
      <select id="data_model_id" class="swal2-input" defaultValue=${config.data_model_id}>
      ${dataModels?.map((model, index) => (
        `<option key=${index} value=${model.id}>${model.name}</option>`
      ))}
      </select>
      `,
      showCancelButton: true,
      confirmButtonText: "Spara",
      cancelButtonText: "Avbryt",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const name = (document.getElementById('name') as HTMLInputElement).value;
        const start_url = (document.getElementById('start_url') as HTMLInputElement).value;
        const follow_links = (document.getElementById('follow_links') as HTMLInputElement).checked;
        const only_follow_start_url_children = (document.getElementById('only_follow_start_url_children') as HTMLInputElement).checked;
        const refresh_interval = (document.getElementById('update_interval') as HTMLInputElement).value;
        const data_model_id = (document.getElementById('data_model_id') as HTMLSelectElement).value;
        const requestBody = {
          id: config.id,
          name: name,
          start_url: start_url,
          follow_links: follow_links,
          only_follow_start_url_children: only_follow_start_url_children,
          refresh_interval: parseInt(refresh_interval),
          data_model_id: data_model_id,
        };
        return requestBody;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        updateWebScrapeConfig(result.value);
      }
    }
    );
  };

  const updateWebScrapeConfig = (requestBody: any) => {
    editWebScrapeConfigMutation.mutate(requestBody);
    if (editWebScrapeConfigMutation.isSuccess) {
      Swal.fire({
        title: "Hemsidan har uppdaterats!",
        icon: "success",
      });
    }
    else if (editWebScrapeConfigMutation.isError) {
      Swal.fire({
        title: "Uppdatering av hemsida misslyckades!",
        text: "Försök igen senare.",
        icon: "error",
      });
    }
  };


  return (
    <AdminListContainer>
      {webScrapeConfigs?.length === 0 ? (
        <AdminListItem>Hittade inga webbplatser</AdminListItem>
      ) : (
        webScrapeConfigs?.map((config, index) => (
        <AdminListItem key={index}>
          <DeleteButton onClick={() => handleDeleteWebScrapeConfigPopup(config.id, config.name, config.start_url)}>
            <FaTrashCan />
          </DeleteButton>
          <ListConfigureButton onClick={() => handleConfigureWebScrapeConfig(config)}>
            <FaWrench />
          </ListConfigureButton>
          {config.name}{" "}
          <a href={config.start_url} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
            {config.start_url}
          </a>{" "}
          {formatDateTime(config.last_updated_at)}
        </AdminListItem>
      ))
    )}
    </AdminListContainer>
  );
};

export default WebScrapeConfigList;