import styled from "styled-components";

export const FeedbackChatSection = styled.div`
  height: calc(100% - 14px);
  width: 50%;
  min-width: 240px;
  min-height: 525px;
  border: solid;
  border-width: 6px 3px 6px 6px;
  border-color: #444;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: safe start;
  overflow-y: auto;
`;

export const MessagesSection = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-bottom: 5px;
`;

export const MessageTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  padding-left: 10px;
  color: #fff;
  margin-bottom: 5px;
`;

export const SeparatorLine = styled.hr`
  width: calc(100% - 20px);
  margin: 0 auto;
  border: 0;
  height: 1px;
  background-color: #aaa;
`;

export const EmailContainer = styled.div`
  width: calc(100% - 30px);
  padding: 20px 10px 10px 20px;
`;

export const EmailToFrom = styled.div`
  
`;

export const EmailInfoText = styled.a`
  font-size: 13px;
  color: #ccc;
`;

export const EmailSender = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 500;
`;

export const EmailContent = styled.div`
  color: #fff;
  margin: 10px 0 20px 0;
`;

export const ToolsSeparatorLine = styled.hr`
  width: 1px;
  margin: 5px 3px 5px 3px;
  border: 0;
  height: auto;
  background-color: #888;
`;

export const SendEmailButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  padding: 10px 20px 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  box-sizing: border-box;
  margin-right: 5px;
  border: none;
  
  &:hover {
    background-color: #0a6073;
  }

  &:active {
    background-color: #1397b7;
  }
`;

export const Review = styled.div`
  width: calc(100% - 40px);
  padding: 10px 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 10px 0 10px;
`;

export const ReviewResponseInput = styled.textarea`
  height: auto;
  color: #fff;
  width: calc(100% - 50px);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  resize: none;
  align-self: center;
  border: none;
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  padding: 15px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  margin: 10px 0 15px 0;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.4), 0 0 1px 1px #000;
  }
`;

export const ReviewRating = styled.span`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
`;

export const ReviewText = styled.p`
  font-size: 16px;
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
  word-break: break-word;
`;