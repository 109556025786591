import { useQuery } from "@tanstack/react-query";
import { User } from "../Types";
import { ax } from "../Utils";



export const useOrganizationUsers = (orgId: string | undefined) => {
  return useQuery<User[], Error>({
    queryKey: ['users', 'all', { org_id: orgId }],
    queryFn: (): Promise<User[]> => ax.get(`/users/all/`).then(res => res.data),
    enabled: !!orgId,
  });
}