import React from 'react';
import {
  ErrandsListSection,
  ErrandsHeader,
  CreateErrandWrapper,
  CreateErrandCard,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Marketing } from '../../Types';
import { useMarketingList } from '../../Queries/useMarketingList';
import { useSelectedMarketing } from '../../Queries/useSelectedMarketing';
import { formatDateTime } from '../../Functions/formatDateTime';

type MarketingCardProps = {
  marketing: Marketing;
  lastClickedId: string | null;
  //isEmptyMarketing: () => void;
};

const MarketingCard = (props: MarketingCardProps) => {
  const {selectedMarketing, updateSelectedMarketing} = useSelectedMarketing();

  return (
    <ErrandCardStyling onClick={() => {
      updateSelectedMarketing(props.marketing);
    }}
      selected={props.lastClickedId === props.marketing.id}
    >
      <ErrandCardTitle>{props.marketing.title}</ErrandCardTitle>
      <ErrandCardText>{props.marketing.text}</ErrandCardText>
      <ErrandCardDate>
        {formatDateTime(props.marketing.last_updated_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  handleNewMarketing: () => void;
  lastClickedId: string | null;
  //updateDraft: () => void;
};

const CampaignList = (props: Props) => {
  const { data: marketings, ...marketingsQuery } = useMarketingList();

  return (
    <ErrandsListSection>
      <ErrandsHeader>Marknadsföring</ErrandsHeader>
      <CreateErrandWrapper>
        <CreateErrandCard onClick={props.handleNewMarketing}>
          Ny Kampanj
        </CreateErrandCard>
      </CreateErrandWrapper>
      <ErrandsListContainer>
        {marketings?.map((marketing, index) => (
          <MarketingCard
            key={index}
            marketing={marketing}
            lastClickedId={props.lastClickedId}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default CampaignList;

