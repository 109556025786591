
import { TAddUserSchema, User, addUserSchema } from "../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { ax } from "../../Utils";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthorizationError } from "../../AuthorizationPages/Authorization-styling";
import { AdminButton, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingSelectBox, AdminSettingText } from "../AdminPage-styling";
import { useCurrentUser } from "../../Queries/useCurrentUser";
import Swal from "sweetalert2";



export default function AddUser() {
  const { data: currentUser } = useCurrentUser();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
  } = useForm<TAddUserSchema>({
    resolver: zodResolver(addUserSchema),
  });

  const onSubmit = async (data: TAddUserSchema) => {
    if (!currentUser) {
      alert("No current user!");
      return;
    }

    const requestBody = {
      company_id: currentUser.org_id,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      is_superuser: data.isSuperuser,
      password: "nils2345W!",
    };

    try {
      await ax.post('/auth/register', requestBody);
    }
    catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          alert("Registrering av användaren misslyckades! Error: " + (error.response.data.message || error.message) +
                "\nKontrollera att användaren inte redan finns i systemet.");
        } else {
          // General error message if not a 400 status
          alert("Registrering av användaren misslyckades! Error: " + error.message);
        }
      } else {
        // If it's not an AxiosError, handle as a general unknown error
        alert("Ett problem uppstod! Error: " + error);
      }
      return;
    }
    const requestBody3 = {
      email: data.email,
    };

    try {
      await ax.post('/auth/request-verify-token', requestBody3);
    }
    catch (error) {
      Swal.fire({
        title: "Bekräftelsemejlet gick inte att skicka!",
        text: "Försök igen senare.",
        icon: "error",
      });
      return;
    }

    toast.success(`Ett bekräftelsemejl har skickats till ${data.email}!`);
    reset();
  };

  return (
    <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
      <AdminSettingContainer>
        <AdminSettingText>Förnamn</AdminSettingText>
        <AdminSettingInput
          {...register("firstName")}
          type="text"
          placeholder="Förnamn"
          id="firstName"
        />
        {errors.firstName && (
          <AuthorizationError>{`${errors.firstName.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminSettingContainer>
        <AdminSettingText>Efternamn</AdminSettingText>
        <AdminSettingInput
          {...register("lastName")}
          type="text"
          placeholder="Efternamn"
          id="lastName"
        />
        {errors.lastName && (
          <AuthorizationError>{`${errors.lastName.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminSettingContainer>
        <AdminSettingText>E-postadress</AdminSettingText>
        <AdminSettingInput
          {...register("email")}
          type="email"
          placeholder="E-postadress"
          id="email"
        />
        {errors.email && (
          <AuthorizationError>{`${errors.email.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      
      <AdminSettingContainer>
        <AdminSettingText>Behörighet</AdminSettingText>
        <AdminSettingSelectBox
          {...register("isSuperuser")}
          id="isSuperuser"
        >
          <option value="false">Medarbetare</option>
          <option value="true">Admin</option>
        </AdminSettingSelectBox>

      </AdminSettingContainer>


      <AdminButton
        disabled={isSubmitting}
        type="submit"
      >
        Lägg till
      </AdminButton>
    </AdminSettingForm>
  );
}