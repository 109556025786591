import { useQuery } from "@tanstack/react-query";
import { User } from "../Types";
import { ax } from "../Utils";


export const useCurrentUser = () => {
  return useQuery<User, Error>({
    queryKey: ['users', 'me'],
    queryFn: (): Promise<User> => ax.get('/users/me').then(res => res.data),
  });
}