import { useQuery } from "@tanstack/react-query"
import { DataModel } from "../Types"
import { ax } from "../Utils"



export const useDataModels = () => {
  return useQuery<DataModel[], Error>({
    queryKey: ['data_models', 'all'],
    queryFn: (): Promise<DataModel[]> => ax.get(`/data_models/all/`).then(res => res.data),
  });
};