import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { User } from "../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton } from "../AdminPage-styling";
import { useOrganizationUsers } from "../../Queries/useOrganizationUsers";
import { useCurrentUser } from "../../Queries/useCurrentUser";
import { ax } from "../../Utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

type CompanyUsersListProps = {
  // handleDeleteUserPopup: (userId: string, firstName: string, lastName: string) => void;
  // handleConfigureUser: (user: User) => void;
};

const CompanyUsersList = (props: CompanyUsersListProps) => {
  
  const permissionsOptions = `
  <option value="false">Användare</option>
  <option value="true">Admin</option>
  `;

  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const { data: organizationUsers, ...organizationUsersQuery } = useOrganizationUsers(currentUser?.org_id);

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return ax.delete(`/users/${userId}`);
    },
    onSuccess(data, userId) {
      queryClient.setQueryData(['users', 'all', { org_id: currentUser?.org_id }], (oldData: User[]) => oldData.filter((user) => user.id !== userId));
      queryClient.invalidateQueries({ queryKey: ['users', 'all', { org_id: currentUser?.org_id }] });
    },
  });

  const editUserMutation = useMutation({
    mutationFn: ({userId, requestBody}: {userId: string, requestBody: any}) => {
      return ax.patch(`/users/${userId}`, requestBody);
    },
    onSuccess(data, variables) {
      queryClient.setQueryData(['users', 'all', { org_id: currentUser?.org_id }], (oldData: User[]) => oldData.map((user) => user.id === variables.userId ? data : user));
      queryClient.invalidateQueries({ queryKey: ['users', 'all', { org_id: currentUser?.org_id }] });
    },
  });

  const handleDeleteUserPopup = (userId: string, firstName: string, lastName: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort användaren ${firstName} ${lastName}?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort användare",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const deleteUser = async (userId: string) => {
    deleteUserMutation.mutate(userId);
    if (deleteUserMutation.isSuccess) {
      Swal.fire({
        title: "Användare borttagen!",
        icon: "success"
      });
    }
    else if (deleteUserMutation.isError) {
      Swal.fire({
        title: "Radering av användare misslyckades!",
        text: "Försök igen senare.",
        icon: "error"
      });
    }
  };

  const handleConfigureUser = (user: User) => {
    Swal.fire({
      title: "Konfigurera användare",
      html: `
        <p>Välj behörighet för användaren</p>
        <select id="permissions" class="swal2-input">
          ${permissionsOptions}
        </select>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Uppdatera användare",
      cancelButtonText: "Avbryt",
      didOpen: () => {
        // Set the selected value here
        const permissionsSelect = document.getElementById('permissions') as HTMLSelectElement;
        permissionsSelect.value = user.is_superuser ? 'true' : 'false';
      },
      preConfirm: () => {
        const newPermission = (document.getElementById('permissions') as HTMLSelectElement).value;
        return { newPermission };
      }

    }).then((result) => {
      if (result.isConfirmed) {
        updateUser(user, result.value.newPermission);
      }
    }
    );
  };

  const updateUser = async (user: User, newPermission: string) => {
    const requestBody = {
      "is_superuser": newPermission === "true"
    };
    editUserMutation.mutate({userId: user.id, requestBody});
    if (editUserMutation.isSuccess) {
      Swal.fire({
        title: "Användare uppdaterad!",
        icon: "success"
      });
    }
    else if (editUserMutation.isError) {
      Swal.fire({
        title: "Uppdatering av användare misslyckades!",
        text: "Error: " + editUserMutation.error,
        icon: "error"
      });
    }
  };



  return (
    <AdminListContainer>
      {organizationUsers?.length === 0 ? (
        <AdminListItem>Hittade inga användare</AdminListItem>
      ) : (
        organizationUsers?.map((user) => (
        <AdminListItem key={user.id}>
          <DeleteButton onClick={() => handleDeleteUserPopup(user.id, user.first_name, user.last_name)}>
            <FaTrashCan />
          </DeleteButton>
          <ListConfigureButton onClick={() => handleConfigureUser(user)}>
            <FaWrench />
          </ListConfigureButton>
          {user.first_name} {user.last_name}
        </AdminListItem>
      ))
    )}
    </AdminListContainer>
  );
};

export default CompanyUsersList;