import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";


export const useContentMarketing = (module: string, obj_id: string | undefined) => {
  //const queryClient = useQueryClient();
  //console.log("content", !!obj_id);
  //const {data: content } = useQuery<string, Error>({
  return useQuery<string, Error>({
    queryKey: ['content', {module}, { obj_id }],
    queryFn: (): Promise<string> => ax.get(`/marketing?obj_id=${obj_id}`).then(res => res.data.body),
    enabled: !!obj_id,
    initialData: '',
    staleTime: 0,
  });

  // const updateContent = (content: string) => {
  //   queryClient.setQueryData<string>(['content', { obj_id }], content);
  // };

  //return { content, updateContent };
};