import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Feedback } from "../Types";


export const useSelectedFeedback = () => {
  const queryClient = useQueryClient();

  const {data: selectedFeedback} = useQuery<Feedback | null, Error>({
    queryKey: ['selected', 'feedback'],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  const updateSelectedFeedback = (feedback: Feedback | null) => {
    queryClient.setQueryData<Feedback | null>(['selected', 'feedback'], feedback);
  };

  return { selectedFeedback, updateSelectedFeedback }
};