import { useQuery } from '@tanstack/react-query';
import { ax } from '../Utils';
import { Email, FeedbackType } from '../Types';

export const useFeedbackData = (feedback_type: FeedbackType | undefined, feedback_id: string | undefined) => {
  return useQuery<Email[], Error>({
    queryKey: [{feedback_type}, `feedback`, {feedback_id}],
    // queryFn: async () => {
    //   const response = await ax.get<Email[]>(`/${feedback_type}/feedback/?feedback_id=${feedback_id}`);
    //   console.log("feedback data", response.data);
    //   return response.data;
    // },
    queryFn: (): Promise<Email[]> => ax.get(`/${feedback_type}/feedback/?feedback_id=${feedback_id}`).then(res => res.data),
    enabled: !!feedback_type && !!feedback_id,
    initialData: [],
    staleTime: 0,
  });
};