import { useForm } from "react-hook-form";
import { CloseButton, PopupOverlay, PopUpTitleContainer } from "../../ErrandsPage/ErrandsPage-styling";
import { AdminSettingContainer, AssistantConfigPopupWindow, PopupForm, PopupFormButtonContainer, PopupFormCancelButton, PopupFormInput, PopupFormLabel, PopupFormSelect, PopupFormSubmitButton, PopupFormTextarea } from "../AdminPage-styling";
import { zodResolver } from "@hookform/resolvers/zod";
import { TAssistantConfigSchema, assistantConfigSchema } from "../../Types";
import { ax } from "../../Utils";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { AuthorizationError } from "../../AuthorizationPages/Authorization-styling";
import { useAssistantLanguages } from "../../Queries/useAssistantLanguages";


type AssistantConfigPopUpProps = {
  toggleAssistantConfigPopUp: () => void;
};

const AssistantConfigPopUp = (props: AssistantConfigPopUpProps) => {
  const { data: assistantLanguages } = useAssistantLanguages();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TAssistantConfigSchema>({
    resolver: zodResolver(assistantConfigSchema),
  });

  const onSubmit = async (data: TAssistantConfigSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      config_name: data.config_name,
      config_description: data.config_description,
      name: data.name,
      background: data.background,
      goal: data.goal,
      purpose: data.purpose,
      scope: data.scope,
      tonality: data.tonality,
      logo_url: data.logo_url,
      default_assistant_language_id: data.default_assistant_language_id,
    };

    try {
      await ax.post('/assistant_config', requestBody);
    }
    catch (error) {
      Swal.fire({
        title: "Kunde inte skapa assistentkonfigurationen!",
        text: "Försök igen senare.",
        icon: "error",
      });
      return;
    }

    toast.success("Assistentkonfigurationen har skapats!");
    reset();
    
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 17 > 100 ? 100 : e.target.scrollHeight - 17) + 'px';
  };

  return (
    <PopupOverlay>
      <AssistantConfigPopupWindow>
        <PopUpTitleContainer>Skapa en ny assistent konfiguration</PopUpTitleContainer>
        <CloseButton onClick={props.toggleAssistantConfigPopUp} />
        <PopupForm onSubmit={handleSubmit(onSubmit)}>
          <AdminSettingContainer>
            <PopupFormLabel>Namnge konfigurationen</PopupFormLabel>
            <PopupFormInput 
              {...register("config_name")}
              type="text"
              placeholder="Konfigurationens namn"
            />
            {errors.config_name && (
              <AuthorizationError>{`${errors.config_name.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Beskriv konfigurationen</PopupFormLabel>
            <PopupFormTextarea 
              {...register("config_description")}
              placeholder="Beskriv konfigurationen"
              rows={2}
              id="config_description"
              onChange={handleTextareaChange}
            />
            {errors.config_description && (
              <AuthorizationError>{`${errors.config_description.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens namn</PopupFormLabel>
            <PopupFormInput 
              {...register("name")}
              type="text"
              placeholder="Assistentens namn"
            />
            {errors.name && (
              <AuthorizationError>{`${errors.name.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens bakgrund</PopupFormLabel>
            <PopupFormTextarea 
              {...register("background")}
              placeholder="Assistentens bakgrund"
              rows={2}
              id="background"
              onChange={handleTextareaChange}
            />
            {errors.background && (
              <AuthorizationError>{`${errors.background.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens mål</PopupFormLabel>
            <PopupFormTextarea 
              {...register("goal")}
              placeholder="Assistentens mål"
              rows={2}
              id="goal"
              onChange={handleTextareaChange}
            />
            {errors.goal && (
              <AuthorizationError>{`${errors.goal.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens syfte</PopupFormLabel>
            <PopupFormTextarea 
              {...register("purpose")}
              placeholder="Assistentens syfte"
              rows={2}
              id="purpose"
              onChange={handleTextareaChange}
            />
            {errors.purpose && (
              <AuthorizationError>{`${errors.purpose.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens omfattning</PopupFormLabel>
            <PopupFormTextarea 
              {...register("scope")}
              placeholder="Assistentens omfattning"
              rows={2}
              id="scope"
              onChange={handleTextareaChange}
            />
            {errors.scope && (
              <AuthorizationError>{`${errors.scope.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Assistentens tonalitet</PopupFormLabel>
            <PopupFormTextarea 
              {...register("tonality")}
              placeholder="Assistentens tonalitet"
              rows={2}
              id="tonality"
              onChange={handleTextareaChange}
            />
            {errors.tonality && (
              <AuthorizationError>{`${errors.tonality.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>URL till assistentens logotyp</PopupFormLabel>
            <PopupFormInput 
              {...register("logo_url")}
              type="text"
              placeholder="URL till assistentens logotyp"
            />
            {errors.logo_url && (
              <AuthorizationError>{`${errors.logo_url.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <AdminSettingContainer>
            <PopupFormLabel>Välj standardspråk för assistenten</PopupFormLabel>
            <PopupFormSelect
              {...register("default_assistant_language_id")}
            >
              {assistantLanguages?.map((language, index) => (
                <option key={index} value={language.id}>{language.name}</option>
              ))}
            </PopupFormSelect>
            {errors.default_assistant_language_id && (
              <AuthorizationError>{`${errors.default_assistant_language_id.message}`}</AuthorizationError>
            )}
          </AdminSettingContainer>

          <PopupFormButtonContainer>
            <PopupFormCancelButton type="button" onClick={props.toggleAssistantConfigPopUp}>Avbryt</PopupFormCancelButton>
            <PopupFormSubmitButton
              type="submit"
              disabled={isSubmitting}
            >
              Skapa
            </PopupFormSubmitButton>
          </PopupFormButtonContainer>

        </PopupForm>

      </AssistantConfigPopupWindow>
    </PopupOverlay>
  );
};

export default AssistantConfigPopUp;