import React from 'react';
import { 
  AISection,
  AssistantHeader,
  AssistantSection, 
  ConversationSection, 
  CopilotChatItem, 
  ChatProfileImgContainer, 
  CopilotProfileImg, 
  CopilotChatItemText, 
  CopilotInputSection, 
  CopilotInput,
  SendIconButton, 
} from '../../MarketingPage/MarketingPage-styling';
import { BlinkingSpan, CopilotImgAnimation } from '../../UserPage/UserPage-styling';
import MarkdownRenderer from '../../Components/MarkdownRenderer';
import { useCurrentUser } from '../../Queries/useCurrentUser';
import { FaPaperPlane } from 'react-icons/fa6';


const companyImageLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ICA_logo.svg/1200px-ICA_logo.svg.png';
//const companyImageLink = null;


type Props = {
  inputText: string;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEnterSend: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  conversationRef: React.RefObject<HTMLDivElement>;
  handleSend: () => void;
  copilotChat: string[];
  answerLoading: boolean[];
};

const FeedbackCopilot = (props: Props) => {
  const { data: currentUser } = useCurrentUser();

  return (
    <AssistantSection>
      <AssistantHeader>AI-assistenten</AssistantHeader>
      <AISection>
        <ConversationSection ref={props.conversationRef} >
          {props.copilotChat.map((item, index)=> {
            if (index % 2 === 0) { // Check if index is even
              return (
                <CopilotChatItem key={index} $isCopilot={true} >
                  {props.answerLoading[index/2] &&
                    <CopilotImgAnimation />
                  }
                <ChatProfileImgContainer $isCopilot={true} >
                  {companyImageLink ? (
                    <CopilotProfileImg src={companyImageLink} alt="AI"/>
                  ) : (
                    "AI"
                  )}
                </ChatProfileImgContainer>
                {props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={16} />
                }
                {!props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={16} />
                }
              </CopilotChatItem>
            )
          } else
            return (
              <CopilotChatItem key={index} $isCopilot={false} >
                <ChatProfileImgContainer $isCopilot={false} >
                  {currentUser?.first_name.charAt(0)}
                </ChatProfileImgContainer>
                <CopilotChatItemText>{item}</CopilotChatItemText>
              </CopilotChatItem>
            )
          })}
        </ConversationSection>
        <CopilotInputSection>
          <SendIconButton onClick={props.handleSend}>
            <FaPaperPlane />
          </SendIconButton>
          <CopilotInput
            id='feedbackCopilotInputId'
            value={props.inputText}
            onChange={props.handleTextareaChange}
            placeholder="Skriv din fråga här..."
            rows={1}
            onKeyDown={props.handleEnterSend}
          />
        </CopilotInputSection>

      </AISection>
    </AssistantSection>
  );
};
export default FeedbackCopilot;