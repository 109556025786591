import { useMemo } from 'react';
import { FeedbackType } from '../Types';
import { useFeedbackData } from './useFeedbackData';

export const useRecipientEmails = (feedback_type: FeedbackType | undefined, feedback_id: string | undefined) => {
  const { data: feedbackData, ...feedbackDataQuery } = useFeedbackData(feedback_type, feedback_id);

  const recipientEmails = useMemo(() => {
    if (!feedbackData || feedbackData.length === 0) {
      return { to: [], cc: [], bcc: [] };
    }

    const sourceEmail = feedbackData[0]?.feedback_source_email;
    const sourceEmailBase = feedbackData[0]?.feedback_source_email_base;

    const lastEmail = feedbackData[feedbackData.length - 1];

    if (!lastEmail) {
      return { to: [], cc: [], bcc: [] };
    }

    const filterEmails = (attendees: { display_name: string, identifier: string }[] = []) =>
      attendees
        .map((a) => a.identifier)
        .filter((email) => email !== sourceEmail && email !== sourceEmailBase);

    const fromAttendee = lastEmail.from_attendee;

    if (!lastEmail.sent_at) {
      console.log("recipient emails draft is draft");
      return {
        to: lastEmail.to_attendees ? [ ...lastEmail.to_attendees.map((a) => a.identifier)] : [],
        cc: lastEmail.cc_attendees ? lastEmail.cc_attendees.map((a) => a.identifier) : [],
        bcc: lastEmail.bcc_attendees ? lastEmail.bcc_attendees.map((a) => a.identifier) : [],
      }
    }
    else {
      console.log("recipient emails not draft is not draft");
      return {
        to: Array.from(
          new Set(filterEmails([...lastEmail.to_attendees, fromAttendee] || []))
        ),
        cc: Array.from(new Set(filterEmails(lastEmail.cc_attendees || []))),
        bcc: Array.from(new Set(filterEmails(lastEmail.bcc_attendees || []))),
      };
    }
  }, [feedbackData]);

  return { data: recipientEmails, ...feedbackDataQuery };
};
