import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Email } from "../Types";


export const useContentFeedback = (module: string, obj_id: string | undefined) => {
 
  return useQuery<string, Error>({
    queryKey: ['content', {module}, { obj_id }],
    queryFn: (): Promise<string> => ax.get(`/email/feedback/?feedback_id=${obj_id}`)
      .then((res) => res.data.find((message: Email) => message.sent_at === null).body),
    enabled: !!obj_id,
    initialData: '',
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

};