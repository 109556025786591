import { useQuery } from "@tanstack/react-query";
import { PricingPlan } from "../Types";
import { ax } from "../Utils";



export const usePricingPlans = () => {
  return useQuery<PricingPlan[], Error>({
    queryKey: ['pricing_plan', 'all'],
    queryFn: (): Promise<PricingPlan[]> => ax.get('/pricing_plan/all/').then(res => res.data),
  });
};