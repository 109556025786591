import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { 
  FeedbackChatSection, 
  MessagesSection, 
  SeparatorLine, 
  MessageTitle, 
  EmailContainer, 
  EmailToFrom, 
  EmailInfoText, 
  EmailSender,
  EmailContent,
  ToolsSeparatorLine,
  SendEmailButton,
  Review,
  ReviewResponseInput,
  ReviewRating,
  ReviewText
} from "../Feedback-styling";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image'
import { MantineProvider } from '@mantine/core';
import { ToolsGroup } from '../../MarketingPage/MarketingPage-styling';
import {FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, 
  FaFont, FaHeading, FaHighlighter, FaItalic, FaLink, FaListOl, FaListUl, 
  FaRedo, FaStrikethrough, FaUnderline, FaUndo, FaUnlink
} from "react-icons/fa";
import { Feedback, FeedbackType, Message, Email } from '../../Types';
import { NoActiveErrandSection, NoActiveErrandText } from '../../ErrandsPage/ErrandsPage-styling';
import EmailTagsInput from '../../Components/EmailTagsInput';
import { useEmailDraftMutation } from '../../Mutations/EmailDraftMutation';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';
import { useDraft } from '../../Queries/useDraft';
import { useFeedbackData } from '../../Queries/useFeedbackData';


type FeedbackProps = {
  feedbackThread: Email[] | undefined;
  timePassed: (date: Date) => string;
  selectedFeedback: Feedback | null;
  handleChange: (html: string, text: string, draft: Email | null, selectedFeedback: Feedback | null) => void;
  cancelHandleChange: () => void;
  content: string;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  sendEmail: (html: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
  emailAddresses: {to: string[], cc: string[], bcc: string[]};
};

const FeedbackChat = (props: FeedbackProps) => {

  return (
    <FeedbackChatSection>
      {props.selectedFeedback?.feedback_source_type_name === 'email' ? 
        <>
          <MessagesSection>
            <MessageTitle>
              {props.selectedFeedback?.title || props.selectedFeedback?.feedback_source_type_name || "Feedback"}
            </MessageTitle>
            <SeparatorLine />
            {props.feedbackThread && props.feedbackThread.length > 0 ?
              props.feedbackThread.map((item, index) => {
                if (!item.sent_at) return null;
                return (
                  <Fragment key={index}>
                  <EmailContainer>
                    <EmailToFrom>
                      {item.from_attendee.display_name ?
                        <EmailInfoText>
                          <EmailSender>{item.from_attendee.display_name}</EmailSender> 
                          &nbsp;&lt;{item.from_attendee.identifier}&gt; 
                          &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan<br />
                          till {item.to_attendees.concat(item.cc_attendees).map((receiver, _recIndex, array) => {
                            const name = receiver.display_name || receiver.identifier;
                            const isLast = _recIndex === array.length - 1;
                            return (
                              <Fragment key={_recIndex}>
                                {name}{!isLast && ', '}
                              </Fragment>
                            );
                          })}
                        </EmailInfoText>
                      : <EmailInfoText>
                          <EmailSender>{item.from_attendee.identifier}</EmailSender>
                          &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan<br />
                          till {item.to_attendees.concat(item.cc_attendees).map((receiver, _recIndex, array) => {
                            const name = receiver.display_name || receiver.identifier;
                            const isLast = _recIndex === array.length - 1;
                            return (
                              <Fragment key={_recIndex}>
                                {name}{!isLast && ', '}
                              </Fragment>
                            );
                          })}
                        </EmailInfoText>
                      }
                    </EmailToFrom>
                    <EmailContent dangerouslySetInnerHTML={{__html: item.body }} />
                  </EmailContainer>
                  <SeparatorLine />
                </Fragment>
              );
              })
            : <></>}
          </MessagesSection>
          <EmailEditor
            handleChange={props.handleChange}
            cancelHandleChange={props.cancelHandleChange}
            selectedFeedback={props.selectedFeedback}
            content={props.content}
            selectedFeedbackRef={props.selectedFeedbackRef}
            sendEmail={props.sendEmail}
            editorRef={props.editorRef}
            emailAddresses={props.emailAddresses}
          />
        </>
      // : props.feedbackType === 'review' ? 
      //   <>
      //     <MessagesSection>
      //       <MessageTitle>
      //         {props.selectedFeedback?.title || props.feedbackType || "Feedback"}
      //       </MessageTitle>
      //       <SeparatorLine />
      //       {props.feedbackChat.map((item, index) => 
      //         <Review>
      //           <EmailToFrom>
      //             <EmailInfoText>
      //               <EmailSender>{item.sender_contact_info.name}</EmailSender> 
      //               &nbsp;&bull;&nbsp; Recension från Google<br />
      //               {/* <ReviewRating>{props.selectedFeedback?.rating}/5</ReviewRating>  */}
      //               &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan
      //             </EmailInfoText>
      //           </EmailToFrom>
      //           <ReviewText>{item.text}</ReviewText>
      //         </Review>
      //       )}
      //     </MessagesSection>
      //     <ReviewResponseInput
      //       placeholder="Svara här"
      //       rows={1}
      //     />
      //   </>
      : <NoActiveErrandSection style={{backgroundColor: 'transparent'}}><NoActiveErrandText>Välj en Feedback</NoActiveErrandText></NoActiveErrandSection>}
    </FeedbackChatSection>
  );
};
export default FeedbackChat;


type Props = {
  handleChange: (html: string, text: string, draft: Email | null, selectedFeedback: Feedback | null) => void;
  cancelHandleChange: () => void;
  selectedFeedback: Feedback | null;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  content: string;
  sendEmail: (html: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
  emailAddresses: {to: string[], cc: string[], bcc: string[]};
};

const EmailEditor = (props: Props) => {
  const [showTools, setShowTools] = useState(false);
  const [showEmailTags, setShowEmailTags] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const emails = props.emailAddresses.bcc.length > 0 ? props.emailAddresses.to.concat(props.emailAddresses.cc).join(', ') + `, Hemlig kopia: ${props.emailAddresses.bcc.join(', ')}` : props.emailAddresses.to.concat(props.emailAddresses.cc).join(', ');
  const toInputRef = useRef<HTMLInputElement>(null);
  const ccInputRef = useRef<HTMLInputElement>(null);
  const bccInputRef = useRef<HTMLInputElement>(null);

  const { selectedFeedback } = useSelectedFeedback();
  const { data: draft } = useDraft(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  const emailDraftMutation = useEmailDraftMutation();
  const { data: feedbackData } = useFeedbackData(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
    ],
    content: props.content,
    onUpdate: ({ editor }) => {
      if(props.selectedFeedbackRef.current === props.selectedFeedback) {
        props.cancelHandleChange(); // Cancel the debounced function
      }
      else {
        props.selectedFeedbackRef.current = props.selectedFeedback;
      }
      props.handleChange(editor.getHTML(), editor.getText(), draft, props.selectedFeedback);
    }
  });

  useEffect(() => {
    if (props.editorRef.current) {
      props.editorRef.current.commands.setContent(props.content.replace(/\n/g, '<br>'));
    }
  }, [props.content])

  useEffect(() => {
    props.editorRef.current = editor;
    const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
    // const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
    console.log("editor", editor?.getHTML());
    // console.log("greatGrandparentElement", greatGrandparentElement?.clientHeight);

    if (proseMirrorElement){
      proseMirrorElement.style.outline = 'none';
      proseMirrorElement.style.minHeight = '9rem';
    }
    // const handleResize = () => {
    //   if (proseMirrorElement && greatGrandparentElement) {
    //     const greatGrandparentHeight = greatGrandparentElement.clientHeight;
    //     const heightPercentage = 100; // Adjust the percentage as needed

    //     if (showTools){
    //       proseMirrorElement.style.height = `${(heightPercentage / 100) * greatGrandparentHeight - 117}px`;
    //     }
    //     else {
    //       proseMirrorElement.style.height = `${(heightPercentage / 100) * greatGrandparentHeight - 72}px`;
    //     }
    //   }
    // };

    // // Initial setting of minHeight
    // handleResize();

    // // Listen for window resize event
    // window.addEventListener('resize', handleResize);

    // // Cleanup event listener on component unmount
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, [editor]);

  useEffect(() => {
    if (selectedFeedback && !draft?.id) {
      const sourceEmail = feedbackData[0]?.feedback_source_email;
      const sourceEmailBase = feedbackData[0]?.feedback_source_email_base;

      console.log("inside ", feedbackData);

      const lastEmail = feedbackData[feedbackData.length - 1];

      if (!lastEmail) {
        // Handle the case where emails array is empty
        return;
      }

      const filterEmails = (attendees: { display_name: string, identifier: string }[] = []) => {
        return attendees.map(a => a.identifier).filter(email => email !== sourceEmail && email !== sourceEmailBase);
      }

      const fromAttendee = lastEmail.from_attendee;

      const requestBody = {
        feedback_id: selectedFeedback.id,
        body: editor?.getHTML() ?? '',
        to_attendees: Array.from(new Set(filterEmails(lastEmail.to_attendees.concat(fromAttendee) || []))).map(identifier => ({ identifier })),
        cc_attendees: Array.from(new Set(filterEmails(lastEmail.cc_attendees || []))).map(identifier => ({ identifier })),
        bcc_attendees: Array.from(new Set(filterEmails(lastEmail.bcc_attendees || []))).map(identifier => ({ identifier }))
      };
      emailDraftMutation.mutate(requestBody, {
      });
    }
  }, [draft])


  useEffect(() => {
    setShowEmailTags(false)
    setShowCc(false)
    setShowBcc(false)
  }, [selectedFeedback])

  useEffect(() => {
    if (showEmailTags && toInputRef.current) {
      toInputRef.current.focus();
    }
  }, [showEmailTags]);

  useEffect(() => {
    if (showCc && ccInputRef.current) {
      ccInputRef.current.focus();
    }
  }, [showCc]);

  useEffect(() => {
    if (showBcc && bccInputRef.current) {
      bccInputRef.current.focus();
    }
  }, [showBcc]);

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor]);

  const handleEmailsChange = (emails: string[], recipientType: string) => {
    if (selectedFeedback) {
      console.log(draft);
      if (draft && draft.id) {
        emailDraftMutation.mutate({
          id: draft.id,
          feedback_id: selectedFeedback.id,
          [recipientType]: emails.map((email) => ({identifier: email})),
          body: editor?.getHTML() ?? '',
        }
      );
      }
      else {
        emailDraftMutation.mutate({
          feedback_id: selectedFeedback.id,
          [recipientType]: emails,
          body: editor?.getHTML() ?? '',
        },
        {
          onSuccess: () => {
            // Handle success if needed
          }
        });
      }
    }
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
      width: 'calc(100% - 20px)',
      border: 'solid 2px #444',
      borderRadius: '20px',
      alignSelf: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      margin: '0 0 5px 0',
      overflow: 'hidden',
      height: 'auto',
      maxHeight: '100%',
      minHeight: '250px',
    }}
    >
      {showEmailTags ?
        <EmailTagsInput
          emailAddresses={props.emailAddresses}
          showCc={showCc}
          showBcc={showBcc}
          setShowCc={setShowCc}
          setShowBcc={setShowBcc}
          toInputRef={toInputRef}
          ccInputRef={ccInputRef}
          bccInputRef={bccInputRef}
          handleEmailsChange={handleEmailsChange}
        />
      : <div style={{padding: '10px 10px 0px 10px', color: '#ddd'}}
          onClick={() => {
            setShowEmailTags(true);
          }}
        >{emails ? emails : 'Mottagare'}</div>
      }
      <MantineProvider>
        <RichTextEditor 
          editor={editor}
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '300px',
            height: 'auto',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >

          <RichTextEditor.Content style={{
              flexGrow: 0,
              height: 'auto',
              overflowY: 'auto',
              color: '#fff',
              padding: '0 10px',
              border: 'none',
            }}
            onClick={() => {
              setShowEmailTags(false)
              setShowCc(false)
              setShowBcc(false)
            }}
          />
          {showTools &&
            <RichTextEditor.Toolbar style={{ display: 'flex', boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.4)', maxWidth: 'fit-content', margin: '5px 10px 10px 10px'}}>
              <ToolsGroup>
                <button 
                  onClick={() => editor?.chain().focus().undo().run()}
                  disabled={!editor?.can().undo()}
                  className={editor?.can().undo() ? '' : 'is-disabled'}
                >
                  <FaUndo/>
                </button>
                <button 
                  onClick={() => editor?.chain().focus().redo().run()} 
                  disabled={!editor?.can().redo()}
                  className={editor?.can().redo() ? '' : 'is-disabled'}
                >
                  <FaRedo/>
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                  className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                  <FaHeading />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleBold().run()}
                  className={editor?.isActive('bold') ? 'is-active' : ''}
                >
                  <FaBold />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleItalic().run()}
                  className={editor?.isActive('italic') ? 'is-active' : ''}
                >
                  <FaItalic />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleUnderline().run()}
                  className={editor?.isActive('underline') ? 'is-active' : ''}
                >
                  <FaUnderline />
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleBulletList().run()}
                  className={editor?.isActive('bulletList') ? 'is-active' : ''}
                >
                  <FaListUl />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                  className={editor?.isActive('orderedList') ? 'is-active' : ''}
                >
                  <FaListOl />
                </button>

                <button
                  onClick={() => editor?.chain().focus().setTextAlign('left').run()}
                  className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
                >
                  <FaAlignLeft />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('center').run()}
                  className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
                >
                  <FaAlignCenter />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
                  className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
                >
                  <FaAlignJustify />
                </button>
                <button
                  onClick={() => editor?.chain().focus().setTextAlign('right').run()}
                  className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
                >
                  <FaAlignRight />
                </button>
              </ToolsGroup>

              <ToolsSeparatorLine/>

              <ToolsGroup>
                <button
                  onClick={() => editor?.chain().focus().toggleStrike().run()}
                  className={editor?.isActive('strike') ? 'is-active' : ''}
                >
                  <FaStrikethrough />
                </button>
                <button
                  onClick={() => editor?.chain().focus().toggleHighlight().run()}
                  className={editor?.isActive('highlight') ? 'is-active' : ''}
                >
                  <FaHighlighter />
                </button>
              </ToolsGroup>

            </RichTextEditor.Toolbar>
          }
          <RichTextEditor.Toolbar style={{ display: 'flex', margin: '5px 10px 10px 10px'}}>
            <SendEmailButton onClick={() => props.sendEmail(editor?.getHTML() ?? '')}>Skicka</SendEmailButton>
            <ToolsGroup style={{alignSelf: 'center'}}>
              <button
                onClick={() => setShowTools(!showTools)}
                className={showTools ? 'is-active' : ''}
              >
                <FaFont/>
              </button>
              <button 
                onClick={setLink}
                className={editor?.isActive('link') ? 'is-active' : ''}
                >
                <FaLink/>
              </button>
              <button
                onClick={() => editor?.chain().focus().unsetLink().run()}
                disabled={!editor?.isActive('link')}
                className={!editor?.isActive('link') ? 'is-disabled' : ''}
              >
                <FaUnlink/>
              </button>
            </ToolsGroup>

          </RichTextEditor.Toolbar>

        </RichTextEditor>
      </MantineProvider>
    </div>
  );
};