import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Marketing } from "../Types";


export const useSelectedMarketing = () => {
  const queryClient = useQueryClient();

  const {data: selectedMarketing} = useQuery<Marketing | null, Error>({
    queryKey: ['selected', 'marketing'],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  const updateSelectedMarketing = (marketing: Marketing | null) => {
    queryClient.setQueryData<Marketing | null>(['selected', 'marketing'], marketing);
  };

  return { selectedMarketing, updateSelectedMarketing }
};