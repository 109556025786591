import {
  ErrandsListSection,
  ErrandsHeader,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Feedback } from '../../Types';
import { formatDateTime } from '../../Functions/formatDateTime';
import { useFeedbackList } from '../../Queries/useFeedbackList';
import { useSelectedFeedback } from '../../Queries/useSelectedFeedback';

type FeedbackCardProps = {
  feedback: Feedback;
  lastClickedId: string | null;
};

const FeedbackCard = (props: FeedbackCardProps) => {
  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();
  
  return (
    <ErrandCardStyling onClick={() => {
      updateSelectedFeedback(props.feedback);
    }}
      selected={props.lastClickedId === props.feedback.id}
    >
      <ErrandCardTitle>{props.feedback.title}</ErrandCardTitle>
      <ErrandCardText>{props.feedback.last_message_content}</ErrandCardText>
      <ErrandCardDate>
        {formatDateTime(props.feedback.last_message_sent_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  lastClickedId: string | null;
};

const FeedbackList = (props: Props) => {
  const { data: feedbacks, ...feedbacksQuery } = useFeedbackList();

  return (
    <ErrandsListSection>
      <ErrandsHeader>Feedback</ErrandsHeader>
      <ErrandsListContainer>
        {feedbacks?.map((feedback, index) => (
          <FeedbackCard
            key={index}
            feedback={feedback}
            lastClickedId={props.lastClickedId}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default FeedbackList;

