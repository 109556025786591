// MarkdownRenderer.tsx
import React from 'react';
import ReactMarkdown, {Components} from 'react-markdown';
import { BlinkingSpan, CopilotChatItemText } from '../UserPage/UserPage-styling';
import styled from 'styled-components';

interface ExternalMarkdownProps {
  markdownText: string;
  fontSize?: number;
  textColor?: string;
}

const MarkdownContainer = styled.div`
  color: #fff;

  line-height: 1.25;
  padding: 0;
  margin: -1rem 0;
`;

const ExternalMarkdown = (mrprops: ExternalMarkdownProps) => {
  const hasContent = mrprops.markdownText.trim().length > 0;

  


  return (
    <MarkdownContainer style={{color: mrprops.textColor}} >
      <ReactMarkdown
      >
        {mrprops.markdownText}
      </ReactMarkdown>
    </MarkdownContainer>
  );
};

export default ExternalMarkdown;